import React from 'react'


export default function Desc({desc, fontSettings}) {
  const style = {
    
  }

  return (
    <div id="splashDesc" className="splashInfoElement Week12Desc" style={style}>
      {desc}
    </div>
  )
}